<template>
  <div class="d-flex justify-space-between flex-column h-100 ">
    <div id="messages-container" ref="messagesContainer" class="messages-container pa-2" style="max-height: 93%; height: 93%; overflow-y: auto">
      <v-alert outlined class="message mt-2 pa-2" v-for="msg in messages">
        <div class="username  d-flex justify-space-between"><span :class="`${msg.user_id == user.id?'info--text':'primary--text'}`">~{{ msg.user ?msg.user.pseudo : ''}}</span><span>{{ msg.human_date }}</span></div>
        <div class="content" v-html="msg.content?msg.content.replace(/\n/g,'<br/>'):''"></div>
      </v-alert>
      <v-row v-if="loading">
        <v-col cols="12">
          <v-progress-circular color="primary"></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div class="chatbox">
      <v-textarea
        v-model="message.content"
        flat outlined rows="1" auto-grow hide-details
                  :placeholder="$t('Tapez votre message ici...')"
      >
        <template #append>
          <v-btn icon color="primary" class="send-button"
                 :loading="sending"
                 @click.stop="send"><v-icon>mdi-send</v-icon></v-btn>
        </template>
      </v-textarea>
    </div>
    <v-snackbar
      v-model="snackbar.active"
      dark
      :color="snackbar.color"
    >
      {{ snackbar.text }}
      <v-btn

        text
        @click="snackbar.active = false"
      >
        {{ $t("Close") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'chat',
    components: {},
    props: {
    },
    data: () => ({
      sending: false,
      loading: false,
      error: null,
      message: {content: '',},
      messages: [],
      stats: [],
      recaptcha_token: '',
      confirmObject: { action: '', text: '', active: false },
      snackbar: { active: false, text: '', color: 'primary' },
      timeout: null
    }),
    computed: {
      ...mapState({
        user: 'user',
        chat: 'chat',
      }),
    },
    watch: {
      chat: {
        handler(v) {
          this.messages = [];
          this.getMessages(true);
        },
        deep: true,
      },
      /*messages() {
        this.$nextTick(() => {
          this.scrollToBottom();
        });
      }*/
    },
    created: function () {
      console.log("created")
      this.getMessages()
      // Hide reCAPTCHA badge:
      // recaptcha.hideBadge();

      // Show reCAPTCHA badge:
      try {
        this.recaptcha()
        const recaptcha = this.$recaptchaInstance

        // recaptcha.showBadge()
      } catch (e) {
      }
    },
    mounted: function () {
      console.log("mounted")
    },
    destroyed: function () {
      this.recaptcha()
      clearTimeout(this.timeout)
    },
    methods: {
      scrollToBottom() {
        const container = this.$refs.messagesContainer
        console.log("container", container.scrollHeight)
        container.scrollTop = container.scrollHeight
        if (container) {
          container.scrollTop = container.scrollHeight;
          console.log('Scrolled to bottom');
        } else {
          console.log('Container not found');
        }
      },
      async recaptcha () {
        // (optional) Wait until recaptcha has been loaded.
        await this.$recaptchaLoaded()

        // Execute reCAPTCHA with action "login".
        // console.log("recaptcha ", token);
        this.recaptcha_token = await this.$recaptcha('login')
        // Do stuff with the received token.
        const recaptcha = this.$recaptchaInstance

        // Hide reCAPTCHA badge:
        // recaptcha.hideBadge()

        // Show reCAPTCHA badge:
        // recaptcha.showBadge()
      },

      send () {
        if (!this.message.content || this.message.content.trim().length < 1) {
          this.toast(this.$t('Veuillez saisir un message valide'), 'red')
          return false
        }



        this.sending = true
        // eslint-disable-next-line no-undef
        axios({
          method: 'post',
          url: '/messages',
          data: {...this.message, type: this.chat.type, type_id: this.chat.typeId},
          validateStatus: (status) => {
            return true
          },
        }).catch(error => {
          this.sending = false
          this.toast('Error', 'red')
          console.log(error)
        }).then(async response => {
          this.sending = false
          if (response.data.error) {
            this.toast(response.data.message, 'red')
            return false
          }
          //this.toast(this.$t('Your message was successful.'), 'success')

          this.message = {
            content: '',
          }
          this.messages.push(response.data)
          this.scrollToBottom();
          // this is now called!
        }).then(() => {
          this.sending = false
        })
      },
      getMessages(first = false) {
        this.loading = false;
        axios({
          method: 'get',
          url: '/messages',
          params: { type: this.chat.type, type_id: this.chat.typeId},
          validateStatus: (status) => {
            return true
          },
        }).catch(error => {
          this.loading = false
          this.toast('Error', 'red')
          console.log(error)
        }).then(async response => {
          this.loading = false
          if (response.data.error) {
            this.toast(response.data.message, 'red')
            return false
          }
          //this.toast(this.$t('Your message was successful.'), 'success')


          this.messages = response.data;

          if(first){
            this.scrollToBottom()
          }
          this.timeout  = setTimeout( this.getMessages, 5000)
          // this is now called!
        }).then(() => {
          this.loading = false
        })
      },
      toast (message, color) {
        this.snackbar.text = message;
        this.snackbar.color = color;
        this.snackbar.active = true;
       /* this.$store.commit('setValue', {
          type: 'display_notification',
          value: { message: message, color: color },
        })*/
      },
    },
  }
</script>

<style scoped>
.chatbox {
  position: relative;
}

.send-button {
  position: absolute;
  bottom: 10px; /* Adjust as needed */
  right: 10px; /* Adjust as needed */
}
</style>
