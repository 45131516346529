<template>
  <div class="d-flex">
    <div class="font-weight-bold primary--text ">
      € {{ singleProductData.price_without_tax }}
    </div>&nbsp;&nbsp;&nbsp;&nbsp; <div
      v-if="singleProductData.reduction_percentage > 0"
      class="gray--text font-weight-bold text-decoration-line-through"
    >
      € {{ singleProductData.price_with_tax }}
    </div>
  </div>
</template>
<script>

  export default {
    name: 'ProductPrice',
    directives: {
    },
    components: {
    },
    props: {
      product: {
        type: Object,
        default: () => {},
      },
    },
    data: () => ({
      singleProductData: {},
    }),
    computed: {

    },
    created () {
      this.singleProductData = this.product
    },
    methods: {

    },
  }
</script>
