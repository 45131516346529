<template>
  <v-dialog
    v-if="!$store.state.login"
    v-model="dialog"
    width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :loading="$store.state.logging"
        :outlined="!$store.state.logging"
        :rounded="$store.state.logging"
        :icon="$store.state.logging"
        v-bind="attrs"
        v-on="on"
      >
        {{ $t("Se Connecter") }}
      </v-btn>
    </template>
    <base-card>
      <v-card-title>
        <router-link to="/">
          <v-img
            width="150"
            src="@/assets/images/logo.png"
            class="mx-auto"
          />
        </router-link>
        <v-spacer />
        <v-btn
          icon
          depressed
          @click.stop="dialog=false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-title class="text-center">
        {{ $t("Rejoins le mouvement de la seconde main et vends sans frais !") }}
      </v-card-title>
      <authentication-form @connected="$emit('connected')" />
    </base-card>
  </v-dialog>
</template>

<script>

  import SignupForm from './SignupForm.vue'
  import LoginForm from './LoginForm.vue'
  import AuthenticationForm from './AuthenticationForm.vue'

  export default {

    directives: {
    },
    components: {
      AuthenticationForm,
      LoginForm,
      SignupForm,
    },
    props: {
    },
    data: () => ({
      signup: true,
      dialog: false,
    }),
    computed: {

    },
    created: function () {

    },
    destroyed: function () {

    },
    methods: {

    },
  }
</script>
<style scoped>

</style>
